#header {
  align-items: flex-start;

  .container {
    max-width: breakpoint-max(md);  // avoid growing menu to end of page
    align-items: center;  // middle
    margin: 0;
    padding: 0;
  }

  .navbar-logo {
    color: $primary;
    z-index: 1000;
    padding: 0.25rem 0;
    height: 90px;  // enforce img to shrink
    box-sizing: content-box;  // padding outside

    img {
      // Like img-fluid, but vertically based.
      width: auto;
      height: 100%;
    }
  }

  .navbar-toggler {
    align-self: center;
  }

  .dropdown-menu {
    // The <ul> dropdown
    margin-top: -0.25rem;  // due to overlap effect below
    border-radius: 0 0 0.25rem 0.25rem;
    border-top: 0;
    z-index: $zindex-dropdown;

    left: -$navbar-nav-link-padding-x;  // align items with padding of top-level

    @include media-breakpoint-up(md) {
      // Only for desktop, on mobile, the menu is always expanded.

      left: -0.8rem;  // align items with padding of top-level

      li:first-child {
        // move contents up, but keep containing menu shadow lower
        margin-top: -1rem;
      }
    }
  }

  .dropdown-item {
    // <a href> in dropdown menu
    &:hover {
      color: $navbar-light-hover-color;
      background-color: transparent;
    }

    &.active, &:active {
      color: $navbar-light-active-color;
      background-color: transparent;
    }
  }

  .dropdown:hover > .dropdown-menu {
    // Show dropdown on hover, no need for JavaScript!
    display: block;
  }

  .dropdown > .dropdown-toggle:active {
    // Without this, clicking will make it sticky
    // Does not affect touch events
    pointer-events: none;
  }

  .nav {
    font-family: Arial, sans-serif;
  }

  @include media-md-up {
    .nav-link {
      padding-right: 0.8rem;
      padding-left: 0.8rem;
    }

    /*
    .nav-page-schedule .nav-link {
      color: $info;
    }
    */
  }

  .nav-link.disabled, .dropdown-item.disabled {
    // For draft pages, still allow to click
    pointer-events: auto;
  }
}

#lang-toggle {
  position: absolute;
  right: 20px;
  top: 22px;
  display: none;
  z-index: 2;

  @include media-breakpoint-up(md) {
    display: block;
  }

  .flag {
    display: block;
    margin: .3rem 0 .8rem 0;

    // not needed: &:last-child { margin-bottom: 0; }
  }
}

$flag-height: 14px;
$flag-width: ($flag-height * 2);

#lang-toggle-mobile {
  display: none;
  margin: 1rem 0;
  border-top: 1px solid $gray-300;
  padding-top: 1rem;
  //line-height: $flag-height;

  @include media-breakpoint-down(sm) {
    display: block;
  }

  a {
    color: inherit;
    margin-right: 1rem;
  }
}

.flag {
  display: inline-block;
  width: $flag-width;
  height: $flag-height;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  opacity: .8;

  &.active {
    opacity: 1;
  }

  // svg images from Wikipedia, optimized with svgo.
  // BASE64 encoding took less space then URL encoding.
  &.nl {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjAwIiBoZWlnaHQ9IjYwMCIgdmlld0JveD0iMCAwIDEyIDYiPjxwYXRoIGZpbGw9IiMyMTQ2OEIiIGQ9Ik0wIDBoMTJ2NkgweiIvPjxwYXRoIGZpbGw9IiNGRkYiIGQ9Ik0wIDBoMTJ2NEgweiIvPjxwYXRoIGZpbGw9IiNBRTFDMjgiIGQ9Ik0wIDBoMTJ2MkgweiIvPjwvc3ZnPg==");
  }

  &.en {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MCAzMCIgd2lkdGg9IjEyMDAiIGhlaWdodD0iNjAwIj48Y2xpcFBhdGggaWQ9ImEiPjxwYXRoIGQ9Ik0wIDB2MzBoNjBWMHoiLz48L2NsaXBQYXRoPjxjbGlwUGF0aCBpZD0iYiI+PHBhdGggZD0iTTMwIDE1aDMwdjE1enYxNUgwekgwVjB6VjBoMzB6Ii8+PC9jbGlwUGF0aD48ZyBjbGlwLXBhdGg9InVybCgjYSkiPjxwYXRoIGQ9Ik0wIDB2MzBoNjBWMHoiIGZpbGw9IiMwMTIxNjkiLz48cGF0aCBkPSJNMCAwbDYwIDMwbTAtMzBMMCAzMCIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjYiLz48cGF0aCBkPSJNMCAwbDYwIDMwbTAtMzBMMCAzMCIgY2xpcC1wYXRoPSJ1cmwoI2IpIiBzdHJva2U9IiNDODEwMkUiIHN0cm9rZS13aWR0aD0iNCIvPjxwYXRoIGQ9Ik0zMCAwdjMwTTAgMTVoNjAiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxMCIvPjxwYXRoIGQ9Ik0zMCAwdjMwTTAgMTVoNjAiIHN0cm9rZT0iI0M4MTAyRSIgc3Ryb2tlLXdpZHRoPSI2Ii8+PC9nPjwvc3ZnPg==");
  }

  span {
    display: none;
  }
}


@include media-md-up {
  #header {
    // Force left with max size
    align-items: flex-start;
  }

  #main-menu {
    align-self: flex-start;  // vertical align top menu links
    flex-shrink: 0;  // don't wrap, fall out of .container

    .navbar-nav {
      // align items to the right
      margin-left: auto;
    }
  }
}

@include media-sm-down {
  #header {
    // Expand dropdowns
    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-menu {
      display: block;
      border: 0;
    }
  }
}

@include media-md-up {
  // Tablet and desktop have a larger header compared to mobile.
  .navbar-nav > li > a {
    line-height: $navbar-height-md;
  }
}
